/** @jsx jsx */
import {jsx, useColorMode} from "theme-ui"
import {useCallback, useEffect, useState} from "react";
import {IShortcut, withShortcut} from 'react-keybind';
import theme from '../gatsby-plugin-theme-ui';

type Props = {
    shortcut: IShortcut
}

const modes = Object.keys(theme.colors.modes);

const label = (mode: String) => {
    switch (mode) {
        case 'light':
            return 'Activate Dark Mode';
        case 'dark':
            return 'Activate AMOLED Dark Mode';
        case 'amoled_dark':
            return 'Activate Light Mode';
        default:
            return 'Activate Light Mode';
    }
}

const buttonStyle = (_: any) => ({
    opacity: 0.65,
    position: `relative`,
    borderRadius: `5px`,
    width: `40px`,
    height: `25px`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    transition: `opacity 0.3s ease`,
    border: `none`,
    outline: `none`,
    background: `none`,
    cursor: `pointer`,
    padding: 0,
    appearance: `none`,
    "&:hover, &:focus": {
        opacity: 1
    },
})

const lightIconStyle = (t) => ({
    position: `relative`,
    width: `24px`,
    height: `24px`,
    borderRadius: `50%`,
    border: `none`,
    backgroundColor: `transparent`,
    transform: `scale(1)`,
    transition: `all 0.45s ease`,
    overflow: `hidden`,
    boxShadow: `inset 8px -8px 0px 0px ${t.colors.toggleIcon}`,
    "&:before": {
        content: `""`,
        position: `absolute`,
        right: `-9px`,
        top: `-9px`,
        height: `24px`,
        width: `24px`,
        border: `none`,
        borderRadius: `50%`,
        transform: `translate(0, 0)`,
        opacity: 1,
        transition: `transform 0.45s ease`,
    },
    "&:after": {
        content: `""`,
        width: `8px`,
        height: `8px`,
        borderRadius: `50%`,
        margin: `-4px 0 0 -4px`,
        position: `absolute`,
        top: `50%`,
        left: `50%`,
        boxShadow:
            `0 -23px 0 ${t.colors.toggleIcon},
             0 23px 0 ${t.colors.toggleIcon}, 
             23px 0 0 ${t.colors.toggleIcon}, 
             -23px 0 0 ${t.colors.toggleIcon}, 
             15px 15px 0 ${t.colors.toggleIcon}, 
             -15px 15px 0 ${t.colors.toggleIcon}, 
             15px -15px 0 ${t.colors.toggleIcon}, 
             -15px -15px 0 ${t.colors.toggleIcon}`,
        transform: `scale(0)`,
        transition: `all 0.35s ease`,
    },
})

const darkIconStyle = (t) => ({
    position: `relative`,
    width: `24px`,
    height: `24px`,
    borderRadius: `50%`,
    border: `4px solid ${t.colors.toggleIcon}`,
    backgroundColor: `toggleIcon`,
    transform: `scale(0.55)`,
    transition: `all 0.45s ease`,
    overflow: `visible`,
    boxShadow: `none`,
    "&:before": {
        content: `""`,
        position: `absolute`,
        right: `-9px`,
        top: `-9px`,
        height: `24px`,
        width: `24px`,
        border: `2px solid ${t.colors.toggleIcon}`,
        borderRadius: `50%`,
        transform: `translate(14px, -14px)`,
        opacity: 0,
        transition: `transform 0.45s ease`,
    },
    "&:after": {
        content: `""`,
        width: `8px`,
        height: `8px`,
        borderRadius: `50%`,
        margin: `-4px 0 0 -4px`,
        position: `absolute`,
        top: `50%`,
        left: `50%`,
        boxShadow:
            `0 -23px 0 ${t.colors.toggleIcon}, 
            0 23px 0 ${t.colors.toggleIcon}, 
            23px 0 0 ${t.colors.toggleIcon}, 
            -23px 0 0 ${t.colors.toggleIcon}, 
            15px 15px 0 ${t.colors.toggleIcon}, 
            -15px 15px 0 ${t.colors.toggleIcon}, 
            15px -15px 0 ${t.colors.toggleIcon}, 
            -15px -15px 0 ${t.colors.toggleIcon}`,
        transform: `scale(1)`,
        transition: `all 0.35s ease`,
    },
})

const iconStyle = (colorMode: String) => {
    switch (colorMode) {
        case 'light':
            return lightIconStyle;
        case 'dark':
            return darkIconStyle;
        case 'amoled_dark':
            return darkIconStyle;
        default:
            return lightIconStyle;
    }
}

// Adapted from: https://codepen.io/aaroniker/pen/KGpXZo and https://github.com/narative/gatsby-theme-novela/blob/714b6209c5bd61b220370e8a7ad84c0b1407946a/%40narative/gatsby-theme-novela/src/components/Navigation/Navigation.Header.tsx

const ColorModeToggle = (props: Props) => {
    const [ colorMode, setColorMode ] = useColorMode();
    const rotateColorMode = () => {
        setColorMode(mode => {
            return modes[(modes.indexOf(mode)+1)%modes.length]
        });
    }
    const keybindingToggle = useCallback(function(e) {
        e.preventDefault();
        return rotateColorMode();
    }, []);
    useEffect(() => {
        const {shortcut} = props;
        shortcut.registerShortcut(keybindingToggle, ['t'], 'Switch Theme', 'Switch the theme from between light, dark, and amoled')
        return () => {
            const {shortcut} = props;
            shortcut.unregisterShortcut(['t'])
        }
    }, [])
    return (
        <button
            onClick={() => rotateColorMode()}
            type="button"
            aria-label={label(colorMode)}
            title={label(colorMode)}
            sx={buttonStyle}
        >
            <div
                sx={iconStyle(colorMode)}
            />
        </button>
    )
}

export default withShortcut(ColorModeToggle);
