/** @jsx jsx */
import React from "react"
import {Global} from "@emotion/core"
import {Box, Container, jsx} from "theme-ui"
import "typeface-ibm-plex-sans"
import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"
import CodeStyles from "../styles/code"
import SkipNavLink from "./skip-nav"
import KeybindingHelp from "./keybinding-help"
import {ShortcutProvider} from "react-keybind";

type LayoutProps = { children: React.ReactNode; className?: string }

const Layout = ({children, className}: LayoutProps) => (
    <React.Fragment>
        <ShortcutProvider>
            <KeybindingHelp />
            <Global
                styles={(_) => ({
                    "*": {
                        boxSizing: `inherit`,
                    },
                    "::selection": {
                        backgroundColor: `primary`,
                        color: `white`,
                    },
                    a: {
                        transition: `all 0.3s ease-in-out`,
                        color: `text`,
                    },
                })}
            />
            <SEO/>
            <Container>
                <Header/>
                <Box sx={{...CodeStyles}} className={className}>
                    {children}
                </Box>
                <Footer/>
            </Container>
        </ShortcutProvider>
    </React.Fragment>
)

export default Layout
