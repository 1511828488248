/** @jsx jsx */
import React, {useCallback, useEffect, useState} from "react"
import {ShortcutConsumer, withShortcut} from "react-keybind";
import {Badge, jsx} from 'theme-ui';
import {ThemeDict} from "../types";

const fullscreenModalStyle = (theme: ThemeDict) => ({
    position: 'fixed',
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: 'translate(-50%, -50%)',
    paddingLeft: '1em',
    paddingRight: '1em',
    zIndex: 1002,
    background: theme.colors.background,
})

const dimOverlayStyle = () => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.8,
    zIndex: 1001,
})

const KeybindingHelp = (props) => {
    const [state, setState] = useState({
        active: false
    })
    const activate = useCallback(e => {
        e.preventDefault();
        setState(({active}) => ({active: !active}));
    }, [state])

    useEffect(() => {
        const {shortcut} = props;
        shortcut.registerShortcut(activate, ['shift+?'], 'Help', 'Show Keyboard Shortcut Help');
        return () => {
            console.log('Unregistering shortcuts')
            const {shortcut} = props;
            shortcut.unregisterShortcut(['shift+?']);
        }
    }, [])
    return <ShortcutConsumer>
        {state.active ?
            ({shortcuts}) =>
                <span>
                    <div sx={dimOverlayStyle()}/>
                    <div sx={fullscreenModalStyle}>
                        <h1>Available Keys</h1>
                        {shortcuts ?
                            <ul>
                                {shortcuts.map(binding => (
                                    <li key={binding.id}>{
                                        binding.keys.map((key, index) => <Badge variant='outline' key={index}>{key}</Badge>
                                        )}<strong>{binding.title}</strong> - {binding.description}</li>
                                ))}
                            </ul> : <h2>No Keys</h2>}
                    </div>
                </span> : () => {
            }}
    </ShortcutConsumer>
}

export default withShortcut(KeybindingHelp)
