/** @jsx jsx */
import React, {useCallback, useEffect} from "react"
import {jsx, Link as TLink} from "theme-ui"
import {Link, navigate} from "gatsby"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import {IShortcut, withShortcut} from "react-keybind";

type NavigationProps = {
    nav: {
        title: string,
        slug: string,
        shortcutKeys: string[],
        shortcutDescription: string,
    }[],
    shortcut: IShortcut,
}

const Navigation = (props: NavigationProps) => {
    const {nav} = props;
    const {basePath} = useMinimalBlogConfig()
    const navItemCallbacks = nav.map(
        item => ({
            ...item,
            callback: useCallback(() => {
                navigate(replaceSlashes(`/${basePath}/${item.slug}`));
            }, [])
        })
    )
    useEffect(() => {
        navItemCallbacks.map(({callback, shortcutKeys, title, shortcutDescription}) => {
            const {shortcut} = props;
            shortcut.registerShortcut(callback, [shortcutKeys], title, shortcutDescription)
        })
        return () => {
            navItemCallbacks.map(({shortcutKeys}) => {
                const {shortcut} = props;
                shortcut.unregisterShortcut([shortcutKeys])
            })
        }
    }, [])
    return (
        <React.Fragment>
            {nav && nav.length > 0 && (
                <nav sx={{"a:not(:last-of-type)": {mr: 3}, fontSize: [1, `18px`], ".active": {color: `heading`}}}>
                    {nav.map((item) => (
                        <TLink key={item.slug} as={Link} activeClassName="active"
                               to={replaceSlashes(`/${basePath}/${item.slug}`)}>
                            {item.title}
                        </TLink>
                    ))}
                </nav>
            )}
        </React.Fragment>
    )
}

export default withShortcut(Navigation);
